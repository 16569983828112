.modelViewer {
  position: absolute;
  right: 20px;
  height: 30px;
  width: 30px;
  padding: 5px;
}

.modelViewer-download {
  bottom: 20px;
}

.modelViewer-info {
  bottom: 65px;
}

.modelViewer-infopanel {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 8px;
  font-size: 12px;
  max-width: 60%;
  pointer-events: none;
  z-index: 9999;
}

.modelViewer-stats {
  position: absolute !important;
}

.threejs-container {
  cursor: all-scroll;
  height: 500px;
  position: relative;
}

.threejs-container:active {
  cursor: grabbing;
}

.modelViewer-origin-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: orange;
  border: 2px solid black;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modelViewer-loading-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c2c2c;
  color: white;
  font-size: 24px;
  z-index: 1001;
  border-radius: 5px;
}

.modelViewer-warningMessage {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffdddd;
  color: #d8000c;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  font-size: 14px;
  z-index: 1000;
  opacity: 1; // Start fully visible
  transition: opacity 1s ease; // Gradual fade-out over 2 seconds
}