.header {
  margin-bottom: 2rem;
}

.header-body {
  border-bottom: 1px solid $border-color;
  padding-bottom: $spacer;
  padding-top: $spacer;
}

.header-pretitle {
  color: $tuijo-gray-600;
  letter-spacing: .08em;
  text-transform: uppercase;
}

.header-title {
  margin-bottom: 0;
}

.header-tabs {
  margin-bottom: -$spacer;
  border-bottom-width: 0;
}

.lang-button {
  z-index: 10;
  position: fixed;
  right: 12px;
  padding: 4px 8px;
  border-radius: 0px 0px 0.2rem 0.2rem;
  background-color: #013770;
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: 40px;
}

@media (max-width: 767px)
{
  .lang-button-adaptable
  {
    margin-right: 60px;
    margin-top: 16px;
    border-radius: 3px;
    position: absolute;
  }
}