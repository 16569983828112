.logo-backend
{
  max-width: 100%;
  max-height: 40px;

  @include media-breakpoint-up(md)
  {
    max-height: 100px;
  }
}

.logo-login
{
  width: 100%;
  max-width: 100%;
  max-height: 120px;
}