.wrong
{
  color: $danger;
}

.good
{
  color: $success;
}

.rotation-container
{
  min-width: 45px;
  max-width: 85px;
}

.model-input
{
  -webkit-appearance: none;
  -moz-appearance: textfield;
  cursor: col-resize;
}

@media (min-width: 576px) and (max-width: 700px) {
  .rotation-input
  {
    padding-left: 6px !important;
  }
}

@media (max-width: 1199px) {
  .rotation-input
  {
    padding-right: 0px !important;
  }
}
